/* eslint-disable @next/next/no-img-element */
"use client";
import { Button, Stack } from "@mui/material";
import { useEffect } from "react";
import React from "react";

const Login = function (props) {
  const { hostName } = props;
  let dialog;
  const [redirectUrl, setRedirectUrl] = React.useState("");

  useEffect(() => {
    Office.onReady(() => {
      // console.log('office loaded');
      //@ts-ignore
      setRedirectUrl(Office.context.mailbox?.targetWindow?.location.origin);
    });
  }, [,]);

  const processMessage = function (arg) {
    if (arg.message === "LoggedIn") {
      dialog.close();
      window.location.replace("/ContentArchiveOnItemRead");
    }
  };

  const openDialog = function () {
    const localUrl = redirectUrl + `/loggedIn`;
    Office.context.ui.displayDialogAsync(
      localUrl,
      { height: 50, width: 40 },
      function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          console.log("result status is:", asyncResult.error.message);
        } else {
          dialog = asyncResult.value;
          dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            processMessage
          );
        }
      }
    );
  };

  return (
    <Stack
      id="login"
      direction="column"
      spacing={2}
      marginBottom={2}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Stack margin={3}>
        <img
          src={
            "https://storage.googleapis.com/bpi-assets/NovusLogo-transparant.png"
          }
          alt="Novus logo"
          width={150}
          height={150}
        />
      </Stack>

      <Stack mt={10}>
        <Button
          variant="contained"
          sx={{ m: 1, width: "80%" }}
          onClick={openDialog}
        >
          Inloggen
        </Button>
        <div style={{ width: "100%", textAlign: "center" }}>
          Wacht op inloggen...
        </div>
      </Stack>
    </Stack>
  );
};

export default Login;
